//import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  } from "react-router-dom";

import Login from './authentication/Login';
import Confirm from './authentication/Confirm';

import { Terms } from './general/legal/Terms';
import { About } from './general/legal/About';
import { Polity } from './general/legal/Polity';
import { PQR } from './general/legal/PQR';
//Admin
import MessageActive from './admin/MessageActive';
import { ApprovalPost } from './admin/ApprovalPost';
import 'mapbox-gl/dist/mapbox-gl.css';
import Payments from './post/Payments';
import Screens from './screen/Screens';

function App() {
  return (
    <Router>
    <div>

      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/login-confirm' element={<Confirm />} />
        <Route path='/' element={<MessageActive/>} />
        <Route path='/pqr' element={<PQR />} />
        <Route path='/payments' element={<Payments />} />
        <Route path='/screens' element={<Screens />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy-policies' element={<Polity />} />
        <Route path='/admin/message' element={<MessageActive />} />
        <Route path='/admin/approval-post' element={<ApprovalPost />} />        
      </Routes>
    </div>
  </Router>
  );
}

export default App;
