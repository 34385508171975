import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import NavBarTop from '../general/NavBar';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import Table from 'react-bootstrap/Table';

const Screens = () => {
    const [cookies] = useCookies(['user']);

    const [loading, setLoading] = useState(true);
    const [initStart] = useState(true);

    const [screenList, setScreensList] = useState();

    const [countPageGetPost, setCountPageGetPost] = useState(0);
    
    useEffect(() => {
        if (initStart) {
            getListscreenList();
        }
    }, [initStart]);


    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const ItemScreen = (item) => {
       
        return  <tr title={item.item.date_order} style={{ background: item.item.active ? "#a1dda1":"#ed83ac" }} key={item.item.screen_id}>
                    <td><a target='_blank' href={'https://pautty.com/'+item.item.code_checksum}>/{item.item.code_checksum}</a></td>
                    <td>
                        <a target='_blank' href={'https://pautty.com/'+item.item.code_checksum}>
                        <div className='row'>
                            <div className='col-4'>
                                <img style={{ padding: 10 }} alt='valla' src={item.item.photo_tv} height={70} />
                            </div>
                            <div className='col-8'>
                                {item.item.name}<br />
                                {item.item.type_service_app}<br />
                                ({item.item.user_id})-{item.item.user_name_user}
                            </div>                            
                        </div>
                        </a>
                    </td>
                    {/* <td>{item.item.type_business}</td> */}
                    
                    <td>
                        {item.item.valid_screen?
                        <span>{' '}<BsFillPatchCheckFill color='#3619F7' /><button style={{ border: 'none', marginLeft: 5 }} onClick={() => {handleSubmitUpdateScreen(item.item.screen_id, false)}}>Cambiar</button></span>
                        :
                        <span>{' '}<BsFillPatchCheckFill color='red' /><button style={{ border: 'none', marginLeft: 5 }} onClick={() => {handleSubmitUpdateScreen(item.item.screen_id, true)}}>Cambiar</button></span>
                        }
                    </td>
                </tr>
    }


    const getListscreenList = () => {
        setLoading(true);
        fetch(config.urlApi + '/api/v1/admin/screen?page='+countPageGetPost, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                setScreensList(data);
                setLoading(false);
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                setLoading(false);
                return e;
            });
    }

    const handleSubmitUpdateScreen = (idCurrent, validScreen) => {

        fetch(config.urlApi + '/api/v1/admin/screen-valid/'+idCurrent, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${cookies.access_token}`
            },
            body: JSON.stringify({ 
              valid_screen: validScreen
            })
          })
            .then((response) => response.json())
            .then(data => {
              if (data.status >= 400) {
                showToastError("Ha ocurrido un error al agregar actualizar la información de la pantalla");
              } else {
                getListscreenList();
              }
            })
            .catch(e => {
              showToastError("Inténtalo más tarde.");
              return e;
            });

    }


    return (
        <div>

            <NavBarTop></NavBarTop>

            {loading ?
                <SpinnerLoadingFixed />
                :
                <>
                    {screenList.length>0 ?
                        <div className="feature-product" style={{ maxWidth: '800px', justifyItems: 'center', justifyContent: 'center', margin: 'auto', paddingTop: 80 }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h3>Pantallas</h3>
                            </div>
                            
                            <div className='col-12' style={{ height: 'auto', margin: 'auto', justifyContent: 'center', justifyItems: 'center', marginBottom: 20 }}>
                            
                                <div className='col-12' style={{ height: '100%', justifyContent: 'center', justifyItems: 'center', margin: 'auto', width: '100%' }}>
                                    <div className='' style={{ margin: 'auto', width: '100%' }}>
                                        
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>Código</th>
                                            {/* <th>Usuario</th> */}
                                            <th>Nombre</th>
                                            {/* <th>Categoría</th> */}
                                            {/* <th>Estado</th> */}
                                            <th>Verificada</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {screenList.map((item, index) => {
                                                return <ItemScreen key={index} item={item} index={index} screen={item.screen_id} setScreensList={setScreensList} />
                                            })}
                                        </tbody>
                                    </Table>

                                       
                                    </div>

                                   
                                    
                                </div>
                            </div>

                        </div>
                        :
                        <div style={{ width: '100%', textAlign: 'center', paddingTop: 80 }}>
                            <h3>No hay más</h3>
                        </div>
                    }

                        <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                            <button onClick={()=>{setCountPageGetPost(countPageGetPost-1);getListscreenList();}} className="button-12" style={{ cursor: 'pointer', border: 'none', marginRight: 10 }}>
                                Atrás
                            </button>
                            
                            <button onClick={()=>{setCountPageGetPost(countPageGetPost+1);getListscreenList();}} className="button-12" style={{ cursor: 'pointer', border: 'none' }}>
                                Siguiente
                            </button>
                        </div>

                </>
            }

            <ToastContainer />

        </div>
    )
}

export default Screens;