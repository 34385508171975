import React, { useState } from 'react';
import { FaAirbnb, FaFacebook, FaInstagram, FaPinterest, FaSnapchat, FaTiktok, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';

let dollarIndianLocale = Intl.NumberFormat('es-CO');

const Blog = ({ itemPostScreen }) => {
    console.log("itemPostScreen option_style");
    console.log(itemPostScreen.option_style);

    const [modalShowModalImageFull, setModalShowModalImageFull] = useState(false);

    return (
        <div className='content-blog-post'>
            <div className='content-1-blog' style={{ background: itemPostScreen.color_description }}>
                {itemPostScreen.option_style === 1 &&
                    <div className='content-2-blog' onClick={()=>{setModalShowModalImageFull(true)}}>
                        <div style={{ background: itemPostScreen.color_description, width: '100%', height: '100%', position: 'absolute', opacity: '0.75' }}></div>

                        <div style={{ width: '100%', height: '100%', position: 'absolute', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                            <div style={{ width: '80%', height: 'auto', margin: 'auto', textAlign: 'center' }}>
                                <ImageLogo urlImage={itemPostScreen.url_image_logo} size={80} animation={'animate__animated animate__bounceInDown'}></ImageLogo>
                                <Title text={itemPostScreen.name} color={'#fff'} fontFamily={itemPostScreen.font_family} animation={'animate__animated animate__bounceInDown'}></Title>
                                <hr style={{ background: '#fff', width: '50%', margin: 'auto', height: 2 }}></hr>
                                <Description text={itemPostScreen.description} color={'#fafafa'} fontFamily={itemPostScreen.font_family_description} animation={'animate__animated animate__bounceInLeft'}></Description>
                                <PriceText text={itemPostScreen.price_product} color={'#fff'} fontFamily={itemPostScreen.font_family_price_product} sizeFont={60} animation={'animate__animated animate__bounceInRight'}></PriceText>

                            </div>
                        </div>

                        <MultiMediaImageVideo 
                            nameAlt={itemPostScreen.name} 
                            urlImage={itemPostScreen.url_image} 
                            addClass={''}
                            thumbUrlImage={itemPostScreen.thumb_url_image} 
                            urlVideo={itemPostScreen.url_video} 
                            widthSize={'100%'} 
                            heightSize={'100%'}
                            showModal={setModalShowModalImageFull}></MultiMediaImageVideo>
                        <SocialMediaLink link={itemPostScreen} colorIcon={'#fff'} classNameLinkA={''} classNameIconsContent={'links-blogs-bottom'} clasNameIcons={'item-social-blog'} optionStyle={1}></SocialMediaLink>

                    </div>
                }

                {itemPostScreen.option_style === 2 &&
                    <div className='content-2-blog'>
                        <div style={{ background: 'radial-gradient(white, 10%, '+itemPostScreen.color_description+')', width: '100%', height: '100%', position: 'absolute', opacity: '0.9' }}></div>

                        <div style={{ width: '100%', height: '100%', position: 'absolute', justifyContent: 'center', justifyItems: 'center', display: 'flex', zIndex: 1 }}>
                            <div className='row content-option-2' style={{ background: '#f8f8f8', width: '80%', height: 'auto', margin: 'auto' }}>
                                <div className='col-md-12 col-lg-7' style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center'  }}>
                                        <div style={{ marginRight: 5 }}>
                                            <ImageLogo urlImage={itemPostScreen.url_image_logo} size={40} animation={'animate__animated animate__bounceInDown'}></ImageLogo>
                                        </div>
                                        <Title text={itemPostScreen.name} color={'#292929'} fontFamily={itemPostScreen.font_family} animation={'animate__animated animate__bounceInDown'}></Title>
                                    </div>
                                    <Description text={itemPostScreen.description} color={'#4e4e4e'} fontFamily={itemPostScreen.font_family_description} animation={'animate__animated animate__bounceInLeft'}></Description>
                                    <PriceText text={itemPostScreen.price_product} color={'#000000'} fontFamily={itemPostScreen.font_family_price_product} sizeFont={40} animation={'animate__animated animate__bounceInRight'}></PriceText>
                                </div>
                                <div className='col-md-12 col-lg-5' style={{ justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                                    <div style={{ margin: 'auto' }} onClick={()=>{setModalShowModalImageFull(true)}}>
                                        <MultiMediaImageVideo 
                                            nameAlt={itemPostScreen.name} 
                                            urlImage={itemPostScreen.url_image} 
                                            addClass={'rounded-circle animate__animated animate__bounceInUp'}
                                            thumbUrlImage={itemPostScreen.thumb_url_image} 
                                            urlVideo={itemPostScreen.url_video} 
                                            widthSize={'100%'} 
                                            heightSize={'auto'}
                                            showModal={setModalShowModalImageFull}></MultiMediaImageVideo>
                                    </div>
                                </div>

                            </div>

                            <SocialMediaLink link={itemPostScreen} colorIcon={'#fff'} classNameLinkA={''} classNameIconsContent={'links-blogs-bottom'} clasNameIcons={'item-social-blog'} optionStyle={2}></SocialMediaLink>

                        </div>

                        {itemPostScreen.url_video !== "" ?
                            <video id="video_tv" autoPlay={true} width={'100%'} height={'100%'} style={{ margin: 0 }} muted>
                                <source src={itemPostScreen.url_video}></source>
                            </video>
                            :
                            <img
                                width={'100%'}
                                height={'100%'}
                                className={"image-blog-main"}
                                src={itemPostScreen.thumb_url_image} alt={itemPostScreen.name}
                            />
                        }

                    </div>
                }



                {itemPostScreen.option_style === 3 &&
                    <div className='content-2-blog'>
                        <div style={{ background: 'radial-gradient(white, 80%, '+itemPostScreen.color_description+')', width: '100%', height: '100%', position: 'absolute', opacity: '0.9' }}></div>
                        <div style={{ position: 'absolute', top: 0, left: 0, width: 150, height: 'auto' }}>
                            <img alt='borde' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/bordes/flores-1.png' width={'100%'} height={'100%'} />
                        </div>
                        <div style={{ position: 'absolute', top: 0, right: 0, width: 150, height: 'auto' }}>
                            <img alt='borde' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/bordes/flores-2.png' width={'100%'} height={'100%'} />
                        </div>
                        <div style={{ position: 'absolute', bottom: 0, left: 0, width: 150, height: 'auto' }}>
                            <img alt='borde' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/bordes/flores-3.png' width={'100%'} height={'100%'} />
                        </div>
                        <div style={{ position: 'absolute', bottom: 0, right: 0, width: 150, height: 'auto' }}>
                            <img alt='borde' src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/bordes/flores-4.png' width={'100%'} height={'100%'} />
                        </div>
                        <div style={{ width: '100%', height: '100%', position: 'absolute', justifyContent: 'center', justifyItems: 'center', display: 'flex', zIndex: 1 }}>
                            <div className='row' style={{ width: '80%', height: 'auto', margin: 'auto' }}>
                                <div className='col-md-12 col-lg-7' style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center'  }}>
                                        <div style={{ marginRight: 5 }}>
                                            <ImageLogo urlImage={itemPostScreen.url_image_logo} size={40} animation={'animate__animated animate__bounceInDown'}></ImageLogo>
                                        </div>
                                        <Title text={itemPostScreen.name} color={'#292929'} fontFamily={itemPostScreen.font_family} animation={'animate__animated animate__bounceInDown'}></Title>
                                    </div>
                                    <Description text={itemPostScreen.description} color={'#4e4e4e'} fontFamily={itemPostScreen.font_family_description} animation={'animate__animated animate__bounceInLeft'}></Description>
                                    <PriceText text={itemPostScreen.price_product} color={'#000000'} fontFamily={itemPostScreen.font_family_price_product} sizeFont={40} animation={'animate__animated animate__bounceInRight'}></PriceText>
                                </div>
                                <div className='col-md-12 col-lg-5' style={{ justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                                    <div style={{ margin: 'auto' }} onClick={()=>{setModalShowModalImageFull(true)}}>
                                        <MultiMediaImageVideo 
                                            nameAlt={itemPostScreen.name} 
                                            urlImage={itemPostScreen.url_image} 
                                            addClass={'rounded-circle animate__animated animate__bounceInUp'}
                                            thumbUrlImage={itemPostScreen.thumb_url_image} 
                                            urlVideo={itemPostScreen.url_video} 
                                            widthSize={'100%'} 
                                            heightSize={'auto'}
                                            showModal={setModalShowModalImageFull}></MultiMediaImageVideo>
                                    </div>
                                </div>

                            </div>

                            <SocialMediaLink link={itemPostScreen} colorIcon={'#292929'} classNameLinkA={''} classNameIconsContent={'links-blogs-bottom'} clasNameIcons={'item-social-blog'} optionStyle={3}></SocialMediaLink>

                        </div>

                        {itemPostScreen.url_video !== "" ?
                            <video id="video_tv" autoPlay={true} width={'100%'} height={'100%'} style={{ margin: 0 }} muted>
                                <source src={itemPostScreen.url_video}></source>
                            </video>
                            :
                            <img
                                width={'100%'}
                                height={'100%'}
                                className={"image-blog-main"}
                                src={itemPostScreen.thumb_url_image} alt={itemPostScreen.name}
                            />
                        }

                    </div>
                }







                {itemPostScreen.option_style === 4 &&
                    <div className='content-2-blog'>
                        <div style={{ background: 'radial-gradient(white, 90%, '+itemPostScreen.color_description+')', width: '100%', height: '100%', position: 'absolute', opacity: '0.9' }}></div>
                        
                        <div style={{  width: '100%', height: '100%', position: 'absolute', justifyContent: 'center', justifyItems: 'center', display: 'flex', zIndex: 1 }}>
                            <div className='row' style={{  width: '85%', height: 'auto', margin: 'auto' }}>
                                <div className='col-md-12 col-lg-12' style={{ textAlign: 'center', justifyContent: 'center' }}>
                                    <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center'  }}>
                                        <div style={{ marginRight: 5 }}>
                                            <ImageLogo urlImage={itemPostScreen.url_image_logo} size={40} animation={'animate__animated animate__bounceInDown'}></ImageLogo>
                                        </div>
                                        <Title text={itemPostScreen.name} color={'#292929'} fontFamily={itemPostScreen.font_family} animation={'animate__animated animate__bounceInDown'}></Title>
                                    </div>
                                    <Description text={itemPostScreen.description} color={'#4e4e4e'} fontFamily={itemPostScreen.font_family_description} animation={'animate__animated animate__bounceInLeft'}></Description>
                                    
                                </div>
                                <SocialMediaLink link={itemPostScreen} colorIcon={'#292929'} classNameIconsContent={'links-blogs-list-vertical row'} classNameLinkA={'link-a-social-blog col-12'} clasNameIcons={'item-social-blog-list'} optionStyle={4}></SocialMediaLink>
                            </div>

                            

                        </div>

                        {itemPostScreen.url_video !== "" ?
                            <video id="video_tv" autoPlay={true} width={'100%'} height={'100%'} style={{ margin: 0 }} muted>
                                <source src={itemPostScreen.url_video}></source>
                            </video>
                            :
                            <img
                                width={'100%'}
                                height={'100%'}
                                className={"image-blog-main"}
                                src={itemPostScreen.thumb_url_image} alt={itemPostScreen.name}
                            />
                        }

                    </div>
                }

            </div>


            

            <Modal
                show={modalShowModalImageFull}
                onHide={() => setModalShowModalImageFull(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Detalle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {itemPostScreen.url_video !== "" ?
                        <video id="video_tv_modal" width={'100%'} height={'100%'} style={{ margin: 0 }} controls>
                            <source src={itemPostScreen.url_video}></source>
                        </video>
                        :
                        <img
                            width={'100%'}
                            height={'100%'}
                            className={"image-blog-main"}
                            src={itemPostScreen.url_image} alt={itemPostScreen.name}
                        />
                    }
                </Modal.Body>
                </Modal>
                
        </div>
    )
}


const Title = ({ text, fontFamily, color, animation }) => {
    return <h1 className={'text-shadow-blog ' + animation} style={{ fontFamily: fontFamily, color: color }}>{text}</h1>
}

const Description = ({ text, fontFamily, color, animation }) => {
    return <p className={'text-shadow-blog ' + animation} style={{ fontFamily: fontFamily, color: color, paddingTop: 12 }}><b>{text}</b></p>
}

const PriceText = ({ text, fontFamily, sizeFont, color, animation }) => {
    return <div className={'text-shadow-blog ' + animation} style={{ paddingTop: 10 }}>
        {(text && text !== '0') &&
            <h1 style={{ fontFamily: fontFamily, color: color, fontSize: sizeFont }}><b>$ {dollarIndianLocale.format(text)}</b></h1>
        }
    </div>
}

const ImageLogo = ({ urlImage, size, animation }) => {
    return <>
        {(urlImage !== '' && urlImage) &&
            <img className={animation} src={urlImage} width={size} style={{ marginBottom: 20 }} />
        }
    </>
}

const SocialMediaLink = ({ link, colorIcon, classNameIconsContent, clasNameIcons, classNameLinkA, optionStyle }) => {
    return <>
        {(link.link_facebook !== '' || link.link_whatsapp !== '' || link.link_instagram !== '' || link.link_twitter !== ''
            || link.link_tiktok !== '' || link.link_youtube !== '' || link.link_airbnb !== '' || link.link_pinterest !== '' || link.link_snapchat !== '') &&
            <div className={classNameIconsContent}>
                {link.link_facebook !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Facebook' href={'https://facebook.com/' + link.link_facebook}><FaFacebook className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>@{link.link_facebook}</>} </a>
                }
                {link.link_whatsapp !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Whatsapp' href={'https://wa.me/57' + link.link_whatsapp}><FaWhatsapp className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>{link.link_whatsapp}</>}</a>
                }
                {link.link_instagram !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Instagram' href={'https://instagram.com/' + link.link_instagram}><FaInstagram className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>@{link.link_instagram}</>}</a>
                }
                {link.link_twitter !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Twitter' href={'https://twitter.com/' + link.link_twitter}><FaTwitter className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>@{link.link_twitter}</>}</a>
                }
                {link.link_tiktok !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Tiktok' href={'https://tiktok.com/@' + link.link_tiktok}><FaTiktok className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>@{link.link_tiktok}</>}</a>
                }
                {link.link_youtube !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Youtube' href={'https://youtube.com/' + link.link_youtube}><FaYoutube className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>{link.link_youtube}</>}</a>
                }
                {link.link_airbnb !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Airbnb' href={'https://airbnb.com/users/show/' + link.link_airbnb}><FaAirbnb className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>{link.link_airbnb}</>}</a>
                }
                {link.link_pinterest !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Pinterest' href={'https://pinterest.com/' + link.link_pinterest}><FaPinterest className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>{link.link_pinterest}</>}</a>
                }
                {link.link_snapchat !== '' &&
                    <a className={classNameLinkA} rel="noreferrer" target='_blank' title='Ir a Snapchat' href={'https://snapchat.com/add/' + link.link_snapchat}><FaSnapchat className={clasNameIcons} size={45} color={colorIcon} />{optionStyle===4&&<>{link.link_snapchat}</>}</a>
                }
            </div>
        }
    </>
}


const MultiMediaImageVideo = ({ urlImage, thumbUrlImage, urlVideo, nameAlt, widthSize, heightSize, addClass, showModal }) => {
    
    return <>
        {urlVideo !== "" ?
            <video id="video_tv" autoPlay={true} width={widthSize} height={heightSize} style={{ margin: 0, borderRadius: 5, cursor: 'pointer' }} muted>
                <source src={urlVideo}></source>
            </video>
            :
            <img
                title='Ver completo'
                onClick={()=>{showModal(true)}}
                width={widthSize}
                height={heightSize}
                style={{ cursor: 'pointer' }}
                className={"image-blog-main "+addClass}
                src={thumbUrlImage} alt={nameAlt}
            />
        }


    </>
}

export default Blog;