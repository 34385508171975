import React from 'react';
import { useCookies } from 'react-cookie';

const ValidLogin = () => {
    const [cookies, setCookies] = useCookies(['user']);
    if (cookies.access_token===undefined) {
        setCookies('SecondsOTP', 0, { path: '/' });
        document.cookie = 'access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'phone=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'username=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'last_name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = '/login';
    }
    return (
        <></>
    )
}

export default ValidLogin;