import React, { useState, useEffect } from 'react';
import HeaderBack from '../general/HeaderBack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import { config } from '../configPautty';
import NavBarTop from '../general/NavBar';
import { BsCheck, BsCheck2, BsCheck2All, BsCheck2Circle } from 'react-icons/bs';

const MessageActive = () => {
  const [cookies, setCookies] = useCookies(['user']);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [users, setUsers] = useState();

  useEffect(() => {
      if (loading) {
          getListListUsersendingActive();
      }
  }, [loading])

  const showToastError = (error) => {
    toast.error(error, {
        position: "bottom-center",
        autoClose: 5000,
        rtl: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    });
}

  const getListListUsersendingActive = () => {
    setSpinnerLoading(true);
    fetch(config.urlApi + '/api/v1/admin/message', {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${cookies.access_token}`
      }
    })
      .then((response) => response.json())
      .then(data => {
        if (data.status === 401) {
          document.cookie = 'access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'phone=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'username=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'last_name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          navigate('/login');
        } else {
          if (!data.status) {
            setUsers(data);
            setLoading(false);
          } 
        }
        setSpinnerLoading(false);
        return data;
      })
      .catch(e => {
        showToastError("Inténtalo más tarde. ");
        return e;
      });
  }

  return (
    <><NavBarTop></NavBarTop>
    <div className="container">
      <div className="profile-wrapper-content mt-70 centerMaxContentPage" style={{ paddingTop: '70px' }}>
      {users&&
        <h5>Usuarios registrados: ({users.length})</h5>
      }
        
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Username</th>
              <th scope="col">Código</th>
              <th scope="col">Creación</th>
              <th scope="col">Activar</th>
              <th scope='col'>Premium</th>
            </tr>
          </thead>
          {users&&
            <tbody style={{ fontSize: 12 }}>
              {users.map((item) => 
                  <tr key={item.phone}>
                  <td>{item.user_id} </td>
                  <th scope="row">@{item.username}</th>
                  <td>{item.code_active} </td>
                  <td>{item.update_code_active} </td>
                  <td>{item.phone}</td>
                  <td>{item.premium_messages?
                    <span><BsCheck2Circle color='green' size={30} /></span>
                    :
                    <span></span>
                    }</td>
                </tr>
              )}
            </tbody>   
          }
        </table>
        {!users &&
            <h2>No hay usuarios para activar.</h2>
        }
      </div>
      <ToastContainer />
      {spinnerLoading&&
          <SpinnerLoadingFixed />
      }
    </div>
    </>
  )
}

export default MessageActive;