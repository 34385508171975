import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BsFillPersonFill, BsTv, BsCartCheck, BsWhatsapp, BsPower, BsPersonFill, BsCurrencyDollar, BsTvFill, BsUiChecks } from "react-icons/bs";
import Offcanvas from 'react-bootstrap/Offcanvas'
import 'bootstrap/dist/css/bootstrap.min.css';
import ValidLogin from './ValidLogin';
import { useCookies } from 'react-cookie';

const NavBarTop = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    const LogoutApp = () => {
        setCookies('SecondsOTP', 0, { path: '/' });
        document.cookie = 'access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'phone=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'username=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'last_name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        navigate('/login');
    }

    return (
        <header className="header">
            <ValidLogin />
            <div className="container">
                <div className="row">
                    <div className="navbar-inner">
                        <div className="left">
                            <div className="canvas_open" onClick={handleShow}>
                                <a href='#!'>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                            </div>
                        </div>
                        <div className='left'>
                        <img alt='admin pautty' width={35} src='https://statics-gridwel.s3.us-east-2.amazonaws.com/img/logopauttycircle.png' />
                        </div>
                        <div className="title">
                            <h2><Link to="/" style={{ fontFamily: "Rampart One" }}><b>Pautty</b></Link> - Administrador</h2>
                        </div>

                        <div className='responsiveNavBarTop' style={{ paddingTop: 5 }}>
                            <Link className="right " style={{ paddingLeft: 18 }} to={"/admin/approval-post"}>
                                <span style={{paddingRight: 4, paddingTop: 3, color: "#fff"}}>Pautas {' '}</span>
                                <div className="bell-btn">
                                    <div className="notifications-btn-c">
                                        <i><BsUiChecks color='#fff' /></i>
                                    </div>
                                </div>
                            </Link>
                            <Link className="right " style={{ paddingLeft: 18 }} to='/'>
                                <span style={{paddingRight: 3, paddingTop: 3, color: "#fff"}}>Usuarios {' '}</span>
                                <div className="bell-btn">
                                    <div className="notifications-btn-c">
                                        <i><BsPersonFill color='#fff' /></i>
                                    </div>
                                </div>
                            </Link>

                            <Link className="right " style={{ paddingLeft: 18 }} to='/payments'>
                                <span style={{paddingRight: 3, paddingTop: 3, color: "#fff"}}>Pagos {' '}</span>
                                <div className="bell-btn">
                                    <div className="notifications-btn-c">
                                        <i><BsCurrencyDollar color='#fff' /></i>
                                    </div>
                                </div>
                            </Link>

                            <Link className="right " style={{ paddingLeft: 18 }} to='/screens'>
                                <span style={{paddingRight: 3, paddingTop: 3, color: "#fff"}}>Pantallas {' '}</span>
                                <div className="bell-btn">
                                    <div className="notifications-btn-c">
                                        <i><BsTvFill color='#fff' /></i>
                                    </div>
                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose}>
                <div className="canvas-menu active">
                    <div className="canvas-menu-full mb-50">

                        <div className="menu-close">
                            <i className="fa fa-close"></i>
                        </div>
{/* 
                        <div className="canvas-header">
                            <div className="ba-navbar-user text-center mb-30">

                                <div className="content">
                                    <h3 className="fullname_ck">{cookies.name} {cookies.last_name}</h3>
                                    <p className="username_ck">@{cookies.username}</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="canvas-nav">
                            <nav>
                                <ul>
                                    <li><Link className="my-profile" to="/admin/approval-post"><i><BsUiChecks /></i> Aprobaciones</Link></li>
                                    <li><Link to="/"><i><BsPersonFill /></i> Usuarios</Link></li>

                                    <li><Link to="/payments"><i><BsCurrencyDollar /></i> Pagos</Link></li>
                                    <li><Link to="/screens"><i><BsTvFill /></i> Pantallas</Link></li>
                                </ul>

                            </nav>
                        </div>
                    </div>
                </div>
            </Offcanvas>

        </header>
    )
}

export default NavBarTop;