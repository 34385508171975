import React, { useEffect, useState } from 'react';
import { config } from '../../configPautty';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { BsArrowRepeat, BsLink45Deg, BsXCircleFill } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import SpinnerLoadingFixed from '../../general/SpinnerLoadingFixed';
import { Link, useNavigate } from 'react-router-dom';
import ContentPost from '../../post/component/ContentPost';
import Blog from '../../post/component/Blog';
import Table from 'react-bootstrap/Table';
import { Form } from 'react-bootstrap';


const ListPostsAdmin = () => {
    const [cookies, setCookies] = useCookies(['user']);
    const navigate = useNavigate();

    const [itemPost, setItemPost] = useState();    

    let widthPreview = (window.screen.width-2);
    let heightPreview = (widthPreview / 1.78);


    const [countPageGetPost, setCountPageGetPost] = useState(0);

    const [spinnerLoadingDeleting, setSpinnerLoadingDeleting] = useState(false);

    const [loading, setLoading] = useState(true);

    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);

    useEffect(() => {
        if (loading) {
            getListPosts();
        }
    }, [])

    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const showToastSuccess = (message) => {
        setCookies('SecondsOTP', 0, { path: '/' });
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const getListPosts = () => {
        setLoading(true);
        fetch(config.urlApi + '/api/v1/admin/posts?page='+countPageGetPost, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                if (data.status === 401) {
                    document.cookie = 'access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'phone=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'username=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'last_name=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    navigate('/login');
                } else {
                    if (!data.status) {
                        setItemPost(data);
                        showToastSuccess("Datos actualizados");
                    } else {
                        //showToastError(data.message);
                        setItemPost();
                    }
                }
                setLoading(false);
                return data;
            })
            .catch(e => {
                setLoading(false);
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }

    

    const ItemCardTable = (props) => {

        const updateStatusPauta = (event) => {
            setLoadingChangeStatus(true);
            fetch(config.urlApi + '/api/v1/change-status-pauta/', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${cookies.access_token}`
                },
                body: JSON.stringify({
                    post_screen_id: props.value.post_screen_id,
                    screen_id: parseInt(props.value.screen_id),
                    status_pauta: event.target.value
                })
            })
                .then((response) => response.json())
                .then(data => {
                    if (!data.status) {
                        // getListPosts();
                        props.value.status_pauta=event.target.value;
                    } else {
                        showToastError(data.message);
                    }
                    setLoadingChangeStatus(false);
                })
                .catch(e => {
                    showToastError("Inténtalo más tarde. ");
                    setLoadingChangeStatus(false);
                    return e;
                });
        }

        return <tr className={(props.value.status_pauta==='3' || props.value.status_pauta==='4')?'table-row-pending-post':''}>
                <td><a rel="noreferrer" target='_blank' href={'https://pautty.com/pauta/'+props.value.post_screen_id}><BsLink45Deg /> #{props.value.post_screen_id}</a></td>
                <td>{props.value.name}</td>
                <td><a rel="noreferrer" target='_blank' href={'https://pautty.com/'+props.value.code_checksum} ><BsLink45Deg /> {props.value.code_checksum}</a></td>
                <td>
                <Form.Group>
                    <Form.Select className='input-form-pautty sombras-tv-card' onChange={updateStatusPauta} value={props.value.status_pauta}>
                        {config.list_status_pauta.map((status) => <option key={status.id} value={status.id}>{status.name}</option>)}
                    </Form.Select>
                </Form.Group>
                </td>
            </tr>
    }

   


    

    return (
        <>
            {!loading&&
                <div className='content-update-status-button'>
                    <button onClick={()=>getListPosts()}><BsArrowRepeat /> Actualizar</button>
                </div>
            }

            {!loading &&
                <section className="feature-product" style={{ maxWidth: '800px', justifyItems: 'center', justifyContent: 'center', margin: 'auto' }}>
                    <div className="product-all">
                        {itemPost &&
                            <div className="row">
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>/pauta/</th>
                                        <th>Nombre</th>
                                        <th>Valla</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {itemPost.map((item) => <ItemCardTable key={item.post_screen_id} value={item} />)}
                                    </tbody>
                                </Table>

                            </div>
                        }
                        {!itemPost &&
                            <h2>No hay Pautas.</h2>
                        }
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <span>Página {countPageGetPost}</span>
                            <br></br>
                            <button onClick={()=>{setCountPageGetPost(countPageGetPost+1);getListPosts();}} className="button-12" style={{ cursor: 'pointer', border: 'none' }}>
                                Siguiente Página
                            </button>
                        </div>
                    </div>
                </section>
            }
            {loading &&
                <div className="d-flex justify-content-center" style={{ height: '120px' }}>
                    <span className="spinner-border spinner-border-sm button-sppiner-pauty"
                        role="status" aria-hidden="true"></span>
                </div>
            }




            {spinnerLoadingDeleting&&
                <SpinnerLoadingFixed />
            }


            <ToastContainer />
        </>
    )
}

export default ListPostsAdmin;