import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../configPautty';
import { useCookies } from 'react-cookie';
import SpinnerLoadingFixed from '../general/SpinnerLoadingFixed';
import NavBarTop from '../general/NavBar';
import { Table } from 'react-bootstrap';
import { BsLink45Deg } from 'react-icons/bs';
let dollarIndianLocale = Intl.NumberFormat('es-CO');

const Payments = () => {
    const [cookies] = useCookies(['user']);

    const [loading, setLoading] = useState(true);
    const [initStart] = useState(true);

    const [postPayment, setPostPayment] = useState();

    const [countPageGetPost, setCountPageGetPost] = useState(0);
    
    useEffect(() => {
        if (initStart) {
            getListPostPayment();
        }
    }, [initStart]);


    const showToastError = (error) => {
        toast.error(error, {
            position: "bottom-center",
            autoClose: 5000,
            rtl: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
        });
    }

    const ItemPayment = (item) => {
       
    //    item.item.total_ammount = item.item.total_ammount.toString().substring(0, item.item.total_ammount.toString().length - 1);
        const dateParseLimitDay = item.item.limit_time_plan.toString().split('T')[0];
        const dateParseLimitHour = item.item.limit_time_plan.toString().split('T')[1].split('.')[0];
        const newDateLimit = new Date(dateParseLimitDay+'T'+dateParseLimitHour);
        const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
        const dayLimit = dateParseLimitDay.split('-')[2];
        return  <tr title={item.item.date_order} style={{ background: item.item.is_payment ? "#a1dda1":"#ed83ac" }} key={item.item.payment_id}>
                    <td>
                        {item.item.payment_id}<br />
                        <span style={{ fontSize: 10 }}>{item.item.id_factura}</span>
                    </td>
                    <td>{item.item.username}</td>
                    <td>{dollarIndianLocale.format(item.item.gain_money)}</td>
                    <td>{dollarIndianLocale.format(item.item.commission)}</td>
                    <td>{dollarIndianLocale.format(item.item.total_ammount)}</td>
                    <td><a rel="noreferrer" target='_blank' href={'https://pautty.com/pauta/'+item.item.post_screen_id}><BsLink45Deg /> #{item.item.post_screen_id}</a></td>
                    <td>
                        {dayLimit} de {monthNames[newDateLimit.getMonth()]} {newDateLimit.getFullYear()} <br /> 
                        {dateParseLimitHour}
                    </td>
                </tr>
    }


    const getListPostPayment = () => {
        setLoading(true);
        fetch(config.urlApi + '/api/v1/admin/payment?page='+countPageGetPost, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${cookies.access_token}`
            }
        })
            .then((response) => response.json())
            .then(data => {
                setPostPayment(data);
                setLoading(false);
                return data;
            })
            .catch(e => {
                showToastError("Inténtalo más tarde. ");
                return e;
            });
    }


    return (
        <div className="cart-wrapper-content" style={{ justifyContent: 'center', justifyItems: 'center', margin: 'auto', background: '#fff'}}>

            <NavBarTop></NavBarTop>


            {loading ?
                <SpinnerLoadingFixed />
                :
                <>

                    {postPayment.length>0 ?
                        <div className="feature-product" style={{ maxWidth: '800px', justifyItems: 'center', justifyContent: 'center', margin: 'auto', paddingTop: 80 }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <h3>Pagos</h3>
                            </div>
                            
                            <div className='col-12' style={{ height: 'auto', margin: 'auto', justifyContent: 'center', justifyItems: 'center', marginBottom: 20, overflow: 'auto' }}>
                            
                                <div className='col-12' style={{ height: '100%', justifyContent: 'center', justifyItems: 'center', margin: 'auto', width: '100%' }}>
                                    <div className='' style={{ margin: 'auto', width: '100%' }}>
                                        
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th># Pago</th>
                                            <th>Username</th>
                                            <th>Ganancias</th>
                                            <th>Comisión</th>
                                            <th>Total</th>
                                            <th>Pauta</th>
                                            <th>Fecha límite</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {postPayment.map((item, index) => {
                                                return <ItemPayment key={index} item={item} index={index} screen={item.payment_id} setPostPayment={setPostPayment} />
                                            })}
                                        </tbody>
                                    </Table>

                                       
                                    </div>

                                   
                                    
                                </div>
                            </div>

                        </div>
                        :
                        <div style={{ width: '100%', textAlign: 'center', paddingTop: 80 }}>
                            <h3>No hay más</h3>
                        </div>
                    }

                        <div style={{ width: '100%', textAlign: 'center', paddingTop: 10 }}>
                            <button onClick={()=>{setCountPageGetPost(countPageGetPost-1);getListPostPayment();}} className="button-12" style={{ cursor: 'pointer', border: 'none', marginRight: 10 }}>
                                Atrás
                            </button>
                            
                            <button onClick={()=>{setCountPageGetPost(countPageGetPost+1);getListPostPayment();}} className="button-12" style={{ cursor: 'pointer', border: 'none' }}>
                                Siguiente
                            </button>
                        </div>

                </>
            }

            <ToastContainer />

        </div>
    )
}

export default Payments;