export const config = {
    urlApi: 'https://api.pautty.com',
    // urlApi: 'http://192.168.1.7:9100',
    // urlApi: 'http://192.168.18.246:9100',
    welcome: {
        en: "Welcome",
        fa: "خوش آمدید"
    },
    list_status_pauta: [
        {"id":"0","name":"Registrado"},
        {"id":"1","name":"Recibido"},
        {"id":"2","name":"Pago Confirmado"},
        {"id":"3","name":"Aprobación de Diseño"},
        {"id":"4","name":"En proceso para publicar"},
        {"id":"5","name":"Publicado"},
        {"id":"6","name":"Finalizado"}
    ],
}
  