import React, { Component } from 'react';
import NavBarTop from '../general/NavBar';
import ListPostsAdmin from './component/ListPostsAdmin';


export class ApprovalPost extends Component {

  render() {
    return (
      <>
        <NavBarTop></NavBarTop>


        <div className="cart-wrapper-content pt-70 centerMaxContentPage" style={{paddingLeft: '10px',paddingRight: '10px',paddingTop: '70px', paddingBottom: 100}}>
          <h3 style={{ width: '100%', textAlign: 'center' }}>Aprobaciones</h3>
          <ListPostsAdmin />

          <div className="d-flex justify-content-center">
            <span className="spinner-border spinner-border-sm button-sppiner-pauty" style={{display: 'none'}} role="status" aria-hidden="true"></span>
          </div>

        </div>

      </>
    )
  }

}